import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MegaMenu } from 'components/mega-menu/megaMenu';
import * as PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getTextWidth } from 'utils/helpers';

import cn from './Navigation.module.scss';

export const CampaignCopyWithRouter = ({ history, match }) => {
  const { advertisers, campaigns } = useSelector(
    (state) => ({
      advertisers: state.advertisers,
      campaigns: state.campaigns,
    }),
    shallowEqual,
  );

  const { advertiser_id, campaign_id, page } = match.params;
  const advertiser = advertisers.find((r) => Number(r.advertiser_id) === Number(advertiser_id)) || {};
  const campaign = campaigns.find((r) => Number(r.campaign_id) === Number(campaign_id)) || {};
  const campaign_width = getTextWidth(campaign.campaign_name);
  const advertiser_width = getTextWidth(advertiser.advertiser_name);
  return (
    <>
      <div className={cn.items}>
        <Link className={cn.page} to="/advertisers">
          Advertisers
        </Link>
        <FontAwesomeIcon icon={['fas', 'chevron-right']} className={cn.icon} />
        <MegaMenu
          labels={advertiser.advertiser_name}
          values={advertisers}
          page="Advertiser"
          width={advertiser_width}
          height={200}
          locations={location}
          onKeyDown={() => history.push(`/advertisers/${advertiser_id}/overview`)}
          color="#1D1D1D"
          iconEnable={false}
        />
        <FontAwesomeIcon icon={['fas', 'chevron-right']} className={cn.icon} />
        <MegaMenu
          labels={campaign.campaign_name}
          values={campaigns}
          page="Campaign"
          width={campaign_width}
          height={47}
        />
        <div className={cn.flex} />
      </div>
    </>
  );
};

CampaignCopyWithRouter.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

export const CampaignCopy = withRouter(CampaignCopyWithRouter);
