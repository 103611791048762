import { lazy } from 'react';

export const LoginPage = lazy(() => import('pages/login/LoginPage'));
export const RequestPasswordPage = lazy(() => import('pages/request-password/RequestPasswordPage'));
export const ResetPasswordPage = lazy(() => import('pages/reset-password/ResetPasswordPage'));
export const NotFoundPage = lazy(() => import('pages/404/NotFoundPage'));
export const NotFoundTabPage = lazy(() => import('pages/404/NotFoundTabPage'));
export const AdvertisersPage = lazy(() => import('pages/advertisers/AdvertisersPage'));
export const AdvertiserPage = lazy(() => import('pages/advertiser/AdvertiserPage'));
export const AdvertiserAuditLogPage = lazy(() => import('pages/advertiser/audit-log/AdvertiserAuditLogPage'));
export const AdvertiserReportsPage = lazy(() => import('pages/advertiser/reports/AdvertiserReportsPage'));
export const AdvertiserReportPage = lazy(() => import('pages/advertiser/report/AdvertiserReportPage'));
export const AdvertiserTagsPage = lazy(() => import('pages/advertiser/tags/AdvertiserTagsPage'));
export const AdvertiserOverviewPage = lazy(() => import('pages/advertiser/overview/AdvertiserOverviewPage'));
export const AdvertiserCreativesPage = lazy(() => import('pages/advertiser/creatives/AdvertiserCreativesPage'));
export const AdvertiserFiltersPage = lazy(() => import('pages/advertiser/filters/AdvertiserFiltersPage'));
export const AdvertiserListsPage = lazy(() => import('pages/advertiser/lists/AdvertiserListsPage'));
export const FilterPage = lazy(() => import('pages/filter/FilterPage'));
export const FilterFeaturesPage = lazy(() => import('pages/filter/features/FilterFeaturesPage'));
export const FilterExcludePage = lazy(() => import('pages/filter/exclude/FilterExcludePage'));
export const FilterCustomPage = lazy(() => import('pages/filter/custom/FilterCustomPage'));
export const FilterAuditPage = lazy(() => import('pages/filter/audit/FilterAuditPage'));
export const StrategyTemplatesPage = lazy(() => import('pages/strategy-templates/StrategyTemplatesPage'));
export const CreativePage = lazy(() => import('pages/creative/CreativePage'));
export const CreativeLineItemsPage = lazy(() => import('pages/creative/line-items/CreativeLineItemsPage'));
export const CreativeConfigurationPage = lazy(() => import('pages/creative/configuration/CreativeConfigurationPage'));
export const TagPage = lazy(() => import('pages/tag/TagPage'));
export const TagConfigurationPage = lazy(() => import('pages/tag/configuration/TagConfigurationPage'));
export const LineItemPage = lazy(() => import('pages/line-item/LineItemPage'));
export const LineItemConfigurationPage = lazy(() => import('pages/line-item/configuration/LineItemConfigurationPage'));
export const LineItemCreativesPage = lazy(() => import('pages/line-item/creatives/LineItemCreativesPage'));
export const LineItemDealsPage = lazy(() => import('pages/line-item/deals/LineItemDealsPage'));
export const ChangeOrderPage = lazy(() => import('pages/change-order/ChangeOrderPage'));
export const ChangeOrderConfigurationPage = lazy(() =>
  import('pages/change-order/configuration/ChangeOrderConfigurationPage'),
);
export const ChangeOrderExperimentsPage = lazy(() =>
  import('pages/change-order/experiments/ChangeOrderExperimentsPage'),
);
export const ChangeOrderAuditPage = lazy(() => import('pages/change-order/audit/ChangeOrderAuditPage'));
export const LineItemVendorFeesPage = lazy(() => import('pages/line-item/vendor-fees/LineItemVendorFeesPage'));
export const LineItemDcpmPage = lazy(() => import('pages/line-item/dcpm/LineItemDcpmPage'));
export const CampaignCopyPage = lazy(() => import('pages/campaign-copy/CampaignCopyPage'));
export const CampaignCopyLineItemsPage = lazy(() => import('pages/campaign-copy/line-items/CampaignCopyLineItemsPage'));
export const CampaignCopyFlightsPage = lazy(() => import('pages/campaign-copy/flights/CampaignCopyFlightsPage'));
export const CampaignCopyCreativesPage = lazy(() => import('pages/campaign-copy/creatives/CampaignCopyCreativesPage'));
export const CampaignPage = lazy(() => import('pages/campaign/CampaignPage'));
export const CampaignOverviewPage = lazy(() => import('pages/campaign/overview/CampaignOverviewPage'));
export const CampaignAuditLogPage = lazy(() => import('pages/campaign/audit-log/CampaignAuditLogPage'));
export const CampaignPacingPage = lazy(() => import('pages/campaign/pacing/CampaignPacingPage'));
export const CampaignKpiGroupPage = lazy(() => import('pages/campaign/kpi-group/CampaignKpiGroupPage'));
export const CampaignFlightsPage = lazy(() => import('pages/campaign/flights/CampaignFlightsPage'));
export const CampaignTeamPage = lazy(() => import('pages/campaign/team/CampaignTeamPage'));
export const CampaignBudgetPage = lazy(() => import('pages/campaign/budget/CampaignBudgetPage'));
export const FlightCopyPage = lazy(() => import('pages/flight-copy/FlightCopyPage'));
export const FlightCopyLineItemsPage = lazy(() => import('pages/flight-copy/line-items/FlightCopyLineItemsPage'));
export const FlightCopyCreativesPage = lazy(() => import('pages/flight-copy/creatives/FlightCopyCreativesPage'));
export const FlightPage = lazy(() => import('pages/flight/FlightPage'));
export const FlightLineItemsPage = lazy(() => import('pages/flight/line-items/FlightLineItemsPage'));
export const FlightCreativesPage = lazy(() => import('pages/flight/creatives/FlightCreativesPage'));
export const FlightStrategiesPage = lazy(() => import('pages/flight/strategies/FlightStrategiesPage'));
export const FlightChangeOrdersPage = lazy(() => import('pages/flight/change-orders/FlightChangeOrdersPage'));
export const FlightAbTestsPage = lazy(() => import('pages/flight/ab-tests/FlightAbTestsPage'));
export const FlightAbTestSegmentsPage = lazy(() => import('pages/flight/ab-test-segments/FlightAbTestSegmentsPage'));
export const FlightExperimentsPage = lazy(() => import('pages/flight/experiments/FlightExperimentsPage'));
export const DealsPage = lazy(() => import('pages/deals/DealsPage'));
export const VendorFeesPage = lazy(() => import('pages/vendor-fees/VendorFeesPage'));
export const ModelsPage = lazy(() => import('pages/models/ModelsPage'));
