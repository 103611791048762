import moment from 'moment';
import { validateChangeOrder } from 'utils/helpers';
import { formatBudgetSpend, formatNumber, formatPercentages, padNumber } from 'utils/numbers';
import {
  checkSort,
  mergeStrategyOverride,
  strategyArrayCount,
  strategyArrayMerge,
  strategyModelArrayMerge,
  strategyObjectCount,
  strategyObjectMerge,
  strategyUpdateCount,
} from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

import {
  getFrequencyCapsName,
  getFrequencyCapsNameCombined,
  normalizeChangeOrderFrequencyCaps,
  normalizeFrequencyCaps,
} from './frequency-caps';

export const normalizeStrategies = (data = [], frequency_intervals = []) =>
  data
    .map((row) => normalizeStrategy(row, data, frequency_intervals))
    .filter((row) => row.bid_algorithm_id !== 18) // 18 === No Bid
    .sort((a, b) => checkSort(a, b, 'strategy_identifier_line_item_strategy_name', 'asc'));

export const normalizeStrategy = (row = {}, rows = [], frequency_intervals = []) => {
  const {
    strategy_id,
    reference_strategy_id,
    line_item_name,
    line_item_id,
    strategy_name,
    impressions,
    budget_weight,
    budget_percent,
    spend_budget_micro_usd,
    impression_budget,
    strategy_budget_type_id,
    flight_start_date_utc,
    flight_finish_date_utc,
    ttl_days,
    intervals_per_day,
    bid_algorithm_json,
    creative_selection_json,
    filter_json,
    pacing_weight_json,
    daily_overspend_factor,
    control_systems_json,
    model_json,
    pricing_json,
    scoring_json,
  } = row;

  const has_bid_algorithm_json = isEmpty(bid_algorithm_json || {});
  const has_creative_selection_json = isEmpty(creative_selection_json || {});
  const has_filter_json = isEmpty(filter_json || {});
  const has_control_systems_json = isEmpty(control_systems_json || {});
  const has_model_json = isEmpty(model_json || {});
  const has_pricing_json = isEmpty(pricing_json || {});
  const has_scoring_json = isEmpty(scoring_json || {});
  const has_pacing_weight_json = pacing_weight_json && pacing_weight_json.length !== 0;

  // creating padded identifier to sort referenced strategies in order
  const strategy_id_digits = padNumber('', String(strategy_id || 0).length);
  let strategy_reference_identifier = Number(`${strategy_id}${strategy_id_digits}`);
  if (reference_strategy_id) {
    strategy_reference_identifier = Number(`${reference_strategy_id}${strategy_id}`);
  }

  const strategy_reference = rows.find((r) => r.strategy_id === reference_strategy_id) || {};
  const strategy_children = rows.filter((r) => r.reference_strategy_id === strategy_id);

  const line_item_filter_id = strategy_reference.line_item_id || line_item_id;

  const line_item_strategy_name = `${line_item_name} ${strategy_name}`;
  const strategy_identifier_line_item_strategy_name = `${strategy_reference_identifier} ${line_item_strategy_name}`;

  const ttl_days_formatted = formatNumber(ttl_days, { format: 'number-comma' });
  const minutes_per_interval = 1440 / intervals_per_day;
  const minutes_per_interval_formatted = formatNumber(minutes_per_interval, { format: 'number-comma' });
  const intervals_per_day_formatted = formatNumber(intervals_per_day, { format: 'number-comma' });
  const daily_overspend_factor_formatted = formatNumber(daily_overspend_factor, { format: 'number-comma' });
  const budget_percent_formatted = formatPercentages(budget_percent * 10000, {
    format: 'percentage-strategy',
    append: 0,
  });
  const spend_budget_usd = formatBudgetSpend(spend_budget_micro_usd);
  const spend_budget_usd_formatted = formatNumber(spend_budget_usd, { format: 'currency', append: '$0' });
  const impression_budget_formatted = formatNumber(impression_budget / 1000, {
    format: 'currency-abbreviated',
    append: 0,
  });
  const impressions_formatted = formatNumber(impressions, { format: 'number-abbreviated', append: 0 });
  const flight_start_date_utc_formatted = moment(flight_start_date_utc).format('MM/DD/YYYY');
  const flight_finish_date_utc_formatted = moment(flight_finish_date_utc).format('MM/DD/YYYY');

  let budget_weight_formatted = formatNumber(budget_weight * 1000, { format: 'number-strategy', append: 0 });
  const budget_weight_uncapped = formatNumber(budget_weight * 1000, { format: 'number-strategy', append: 0 });

  if (strategy_budget_type_id !== 1) {
    budget_weight_formatted = formatNumber(budget_weight * 10000, { format: 'percentage-strategy', append: 0 });
  }
  const frequency_caps = normalizeFrequencyCaps(row.frequency_caps, frequency_intervals);
  const strategy_frequency_caps = frequency_caps.filter((f) => f.frequency_cap_id);
  const has_strategy_frequency_caps = strategy_frequency_caps.length !== 0;

  const line_item_frequency_caps = frequency_caps.filter((f) => f.line_item_id && !f.strategy_id);
  const has_line_item_frequency_caps = line_item_frequency_caps.length !== 0;

  const strategy_frequency_cap_name = getFrequencyCapsName(strategy_frequency_caps);
  const line_item_frequency_cap_name = getFrequencyCapsName(line_item_frequency_caps);

  const budget_weight_name = getBudgetWeightName({ strategy_budget_type_id, budget_weight_formatted });

  return {
    ...row,
    budget_weight_uncapped,
    daily_overspend_factor_formatted,
    has_bid_algorithm_json,
    has_creative_selection_json,
    has_filter_json,
    has_pacing_weight_json,
    has_control_systems_json,
    has_model_json,
    has_scoring_json,
    has_pricing_json,
    strategy_reference_identifier,
    strategy_identifier_line_item_strategy_name,
    strategy_identifier_line_item_strategy_name_with_id: `${line_item_filter_id} ${strategy_identifier_line_item_strategy_name}`,
    strategy_children,
    strategy_reference,
    spend_budget_usd,
    spend_budget_usd_formatted,
    budget_percent_formatted,
    impression_budget_formatted,
    impressions_formatted,
    budget_weight_name,
    line_item_filter_id,
    frequency_caps,
    strategy_frequency_caps,
    has_strategy_frequency_caps,
    strategy_frequency_cap_name,
    line_item_frequency_caps,
    has_line_item_frequency_caps,
    line_item_frequency_cap_name,
    budget_weight_formatted,
    flight_start_date_utc_formatted,
    flight_finish_date_utc_formatted,
    line_item_strategy_name,
    minutes_per_interval,
    minutes_per_interval_formatted,
    intervals_per_day_formatted,
    ttl_days_formatted,
    compress: true,
    uuid: uuidv4(),
  };
};

const getBudgetWeightName = (row) => {
  const { budget_weight_formatted, strategy_budget_type_id } = row;

  // absolute number
  // will be concated with budget weight totals in the render cell
  if (strategy_budget_type_id === 1) {
    return `${budget_weight_formatted} / `;
  }
  // percentage already formatted
  if (strategy_budget_type_id === 2) {
    return budget_weight_formatted;
  }
  // percentage uncapped
  if (strategy_budget_type_id === 3) {
    return `Uncapped (${budget_weight_formatted})`;
  }
  return '';
};

const isEmpty = (obj) => Object.keys(obj).length !== 0;

export const normalizeChangeOrderStrategies = (data = [], frequency_intervals = []) =>
  data
    .map((row) => hydrateChangeOrderStrategy(row))
    .map((row) => normalizeChangeOrderStrategy(row, data, frequency_intervals))
    .filter((row) => row.strategy_bid_algorithm_id_combined !== 18) // 18 === No Bid
    .sort((a, b) => checkSort(a, b, 'strategy_identifier_line_item_strategy_name_combined', 'asc'));

export const hydrateChangeOrderStrategy = (row = {}) => {
  const {
    strategy_reference_strategy_override_flag,
    strategy_reference_change_order_strategy_id,
    strategy_reference_change_order_strategy_id_override,
    strategy_reference_strategy_name_override,
    strategy_reference_strategy_name,
  } = row;

  // special case when removing a referenced strategy
  // null would be acceptable for referenced strategy
  const strategy_reference_change_order_strategy_id_combined = strategy_reference_strategy_override_flag
    ? strategy_reference_change_order_strategy_id_override
    : strategy_reference_change_order_strategy_id;

  // also need to apply the logic to the reference name
  let strategy_reference_change_order_strategy_name = strategy_reference_strategy_name;

  if (strategy_reference_strategy_override_flag) {
    strategy_reference_change_order_strategy_name = strategy_reference_strategy_name_override;
  }

  const strategy_updates = countStrategyUpdates(row);
  const strategy_changes = mergeStrategyChanges(row);

  return {
    ...row,
    ...strategy_updates,
    ...strategy_changes,
    strategy_reference_change_order_strategy_name,
    strategy_reference_change_order_strategy_id,
    strategy_reference_change_order_strategy_id_combined,
    strategy_control_systems_json_unsaved: false,
    strategy_model_json_unsaved: false,
    strategy_pricing_json_unsaved: false,
    strategy_scoring_json_unsaved: false,
    strategy_filter_json_unsaved: false,
    strategy_creative_selection_json_unsaved: false,
    strategy_bid_algorithm_json_unsaved: false,
    strategy_pacing_weight_json_unsaved: false,
  };
};

export const normalizeChangeOrderStrategy = (row = {}, rows = [], frequency_intervals = []) => {
  const {
    change_order_strategy_id,
    strategy_reference_change_order_strategy_id_combined,
    strategy_id_override,
    strategy_reference_strategy_id_override,
    line_item_name_combined,
    line_item_id_combined,
    strategy_name_combined,
    strategy_budget_weight_combined,
    strategy_budget_percent_combined,
    strategy_spend_budget_micro_usd_combined,
    strategy_impression_budget_combined,
    strategy_budget_type_id_combined,
    strategy_daily_overspend_factor_combined,
    line_item_flight_start_date_utc_combined,
    line_item_flight_finish_date_utc_combined,
    strategy_ab_test_segment_ttl_days_combined,
    strategy_intervals_per_day_combined,
    strategy_creative_selection_json_combined,
    strategy_filter_json_combined,
    strategy_control_systems_json_combined,
    strategy_model_json_combined,
    strategy_pricing_json_combined,
    strategy_scoring_json_combined,
    strategy_pacing_weight_json_combined,
    strategy_impressions,
    strategy_reference_change_order_strategy_name,
  } = row;

  // checking strategy JSON
  const has_strategy_creative_selection_json = isEmpty(strategy_creative_selection_json_combined || {});
  const has_strategy_filter_json = isEmpty(strategy_filter_json_combined || {});
  const has_strategy_control_systems_json = isEmpty(strategy_control_systems_json_combined || {});
  const has_strategy_model_json = isEmpty(strategy_model_json_combined || {});
  const has_strategy_pricing_json = isEmpty(strategy_pricing_json_combined || {});
  const has_strategy_scoring_json = isEmpty(strategy_scoring_json_combined || {});
  const has_strategy_pacing_weight_json =
    strategy_pacing_weight_json_combined && strategy_pacing_weight_json_combined.length !== 0;

  // creating padded identifier to sort referenced strategies in order
  const strategy_id_digits_combined = padNumber('', String(change_order_strategy_id || 0).length);
  let strategy_reference_identifier_combined = Number(`${change_order_strategy_id}${strategy_id_digits_combined}`);

  if (strategy_reference_change_order_strategy_id_combined) {
    strategy_reference_identifier_combined = Number(
      `${strategy_reference_change_order_strategy_id_combined}${change_order_strategy_id}`,
    );
  }

  const strategy_reference_combined =
    rows.find((r) => r.strategy_id_combined === strategy_reference_change_order_strategy_id_combined) || {};
  const strategy_children_combined = rows.filter(
    (r) => r.strategy_reference_strategy_id_combined === change_order_strategy_id,
  );

  const line_item_filter_id_combined = strategy_reference_combined.line_item_id_combined || line_item_id_combined;

  const line_item_strategy_name_combined = `${line_item_name_combined} ${strategy_name_combined}`;
  const strategy_identifier_line_item_strategy_name_combined = `${strategy_reference_identifier_combined} ${line_item_strategy_name_combined}`;

  const ttl_days_formatted_combined = formatNumber(strategy_ab_test_segment_ttl_days_combined, {
    format: 'number-comma',
  });
  const strategy_minutes_per_interval_combined = 1440 / strategy_intervals_per_day_combined;
  const strategy_minutes_per_interval_formatted_combined = formatNumber(strategy_minutes_per_interval_combined, {
    format: 'number-comma',
  });
  const strategy_intervals_per_day_formatted_combined = formatNumber(strategy_intervals_per_day_combined, {
    format: 'number-comma',
  });
  const strategy_daily_overspend_factor_formatted_combined = formatNumber(strategy_daily_overspend_factor_combined, {
    format: 'number',
  });
  const strategy_budget_percent_formatted_combined = formatPercentages(strategy_budget_percent_combined, {
    format: 'percentage-rounded',
  });
  const strategy_spend_budget_usd_combined = formatBudgetSpend(strategy_spend_budget_micro_usd_combined);
  const strategy_spend_budget_usd_formatted_combined = formatNumber(strategy_spend_budget_usd_combined, {
    format: 'currency',
    append: '$0',
  });
  const strategy_impression_budget_formatted_combined = formatNumber(strategy_impression_budget_combined / 1000, {
    format: 'currency-abbreviated',
    append: 0,
  });
  const strategy_impressions_formatted_combined = formatNumber(strategy_impressions, {
    format: 'number-abbreviated',
    append: 0,
  });

  const strategy_budget_weight_uncapped_combined = formatNumber(strategy_budget_weight_combined * 1000, {
    format: 'number-strategy',
    append: 0,
  });
  let strategy_budget_weight_formatted_combined = formatNumber(strategy_budget_weight_combined * 1000, {
    format: 'number-strategy',
    append: 0,
  });

  if (strategy_budget_type_id_combined !== 1) {
    strategy_budget_weight_formatted_combined = formatNumber(strategy_budget_weight_combined * 10000, {
      format: 'percentage-strategy',
      append: 0,
    });
  }

  const flight_start_date_utc_formatted_combined = moment(line_item_flight_start_date_utc_combined).format(
    'MM/DD/YYYY',
  );
  const flight_finish_date_utc_formatted_combined = moment(line_item_flight_finish_date_utc_combined).format(
    'MM/DD/YYYY',
  );

  const frequency_caps_combined = normalizeChangeOrderFrequencyCaps(row.frequency_caps, frequency_intervals);
  const strategy_frequency_caps_combined = frequency_caps_combined;
  const has_strategy_frequency_caps = strategy_frequency_caps_combined.length !== 0;

  const line_item_frequency_caps_combined = frequency_caps_combined.filter((f) => f.line_item_id && !f.strategy_id);
  const has_line_item_frequency_caps = line_item_frequency_caps_combined.length !== 0;

  const strategy_frequency_cap_name_combined = getFrequencyCapsNameCombined(strategy_frequency_caps_combined);
  const line_item_frequency_cap_name_combined = getFrequencyCapsNameCombined(line_item_frequency_caps_combined);

  const budget_weight_name_combined = getBudgetWeightName({
    strategy_budget_type_id: strategy_budget_type_id_combined,
    budget_weight_formatted: strategy_budget_weight_formatted_combined,
  });

  const strategy_frequency_cap_count = frequency_caps_combined.reduce((acc, next) => acc + next.frequency_cap_count, 0);

  const invalid_strategy = validateChangeOrder(row);
  const strategy_updated = row.strategy_updated + strategy_frequency_cap_count > 0 ? 1 : 0;
  let referenced_strategy_name_combined = '';
  const reference_strategy_name_combined = `${strategy_id_override}: ${strategy_name_combined}`;
  if (strategy_reference_change_order_strategy_id_combined) {
    referenced_strategy_name_combined = `${strategy_reference_strategy_id_override}: ${strategy_reference_change_order_strategy_name}`;
  }

  return {
    ...row,
    invalid_strategy,
    strategy_updated,
    reference_strategy_name_combined,
    referenced_strategy_name_combined,
    strategy_frequency_cap_count,
    // has_strategy_bid_algorithm_json,
    has_strategy_creative_selection_json,
    has_strategy_filter_json,
    has_strategy_pacing_weight_json,
    strategy_reference_identifier_combined,
    strategy_identifier_line_item_strategy_name_combined,
    strategy_children_combined,
    strategy_reference_combined,
    strategy_spend_budget_usd_combined,
    strategy_spend_budget_usd_formatted_combined,
    strategy_budget_percent_formatted_combined,
    strategy_impression_budget_formatted_combined,
    strategy_impressions_formatted_combined,
    strategy_budget_weight_formatted_combined,
    strategy_budget_weight_uncapped_combined,
    strategy_daily_overspend_factor_formatted_combined,
    budget_weight_name_combined,
    line_item_filter_id_combined,
    frequency_caps_combined,
    strategy_frequency_caps_combined,
    has_strategy_frequency_caps,
    strategy_frequency_cap_name_combined,
    line_item_frequency_caps_combined,
    has_line_item_frequency_caps,
    line_item_frequency_cap_name_combined,
    flight_start_date_utc_formatted_combined,
    flight_finish_date_utc_formatted_combined,
    line_item_strategy_name_combined,
    line_item_strategy_name_combined_with_id: `${line_item_filter_id_combined} ${line_item_strategy_name_combined}`,
    strategy_minutes_per_interval_combined,
    strategy_minutes_per_interval_formatted_combined,
    strategy_intervals_per_day_formatted_combined,
    ttl_days_formatted_combined,
    compress: true,
    has_strategy_control_systems_json,
    has_strategy_model_json,
    has_strategy_pricing_json,
    has_strategy_scoring_json,
    uuid: uuidv4(),
  };
};

const mergeStrategyChanges = (data) => ({
  line_item_ab_test_segment_id_combined: mergeStrategyOverride(
    data.line_item_ab_test_segment_id_override,
    data.line_item_ab_test_segment_id,
  ),
  line_item_ab_test_segment_name_combined: mergeStrategyOverride(
    data.line_item_ab_test_segment_name_override,
    data.line_item_ab_test_segment_name,
  ),
  line_item_campaign_id_combined: mergeStrategyOverride(
    data.line_item_campaign_id_override,
    data.line_item_campaign_id,
  ),
  line_item_campaign_name_combined: mergeStrategyOverride(
    data.line_item_campaign_name_override,
    data.line_item_campaign_name,
  ),
  line_item_campaign_spend_budget_micro_usd_combined: mergeStrategyOverride(
    data.line_item_campaign_spend_budget_micro_usd_override,
    data.line_item_campaign_spend_budget_micro_usd,
  ),
  line_item_flight_finish_date_utc_combined: mergeStrategyOverride(
    data.line_item_flight_finish_date_utc_override,
    data.line_item_flight_finish_date_utc,
  ),
  line_item_flight_name_combined: mergeStrategyOverride(
    data.line_item_flight_name_override,
    data.line_item_flight_name,
  ),
  line_item_flight_start_date_utc_combined: mergeStrategyOverride(
    data.line_item_flight_start_date_utc_override,
    data.line_item_flight_start_date_utc,
  ),
  line_item_flight_state_id_combined: mergeStrategyOverride(
    data.line_item_flight_state_id_override,
    data.line_item_flight_state_id,
  ),
  line_item_flight_state_name_combined: mergeStrategyOverride(
    data.line_item_flight_state_name_override,
    data.line_item_flight_state_name,
  ),
  line_item_flight_id_combined: mergeStrategyOverride(data.line_item_flight_id_override, data.line_item_flight_id),
  line_item_id_combined: mergeStrategyOverride(data.line_item_id_override, data.line_item_id),
  line_item_name_combined: mergeStrategyOverride(data.line_item_name_override, data.line_item_name),
  line_item_state_id_combined: mergeStrategyOverride(data.line_item_state_id_override, data.line_item_state_id),
  line_item_state_name_combined: mergeStrategyOverride(data.line_item_state_name_override, data.line_item_state_name),
  // if the override flag is true, we want to consider the override value even if null
  strategy_ab_test_id_combined: mergeStrategyOverride(
    data.strategy_ab_test_id_override,
    data.strategy_ab_test_segment_id_override_flag ? data.strategy_ab_test_id_override : data.strategy_ab_test_id,
  ),
  strategy_ab_test_name_combined: mergeStrategyOverride(
    data.strategy_ab_test_name_override,
    data.strategy_ab_test_segment_id_override_flag ? data.strategy_ab_test_name_override : data.strategy_ab_test_name,
  ),

  strategy_ab_test_segment_id_combined: mergeStrategyOverride(
    data.strategy_ab_test_segment_id_override,
    data.strategy_ab_test_segment_id_override_flag
      ? data.strategy_ab_test_segment_id_override
      : data.strategy_ab_test_segment_id,
  ),
  model_ids_combined: strategyModelArrayMerge(data.model_ids_override, data.model_ids),
  strategy_ab_test_segment_name_combined: mergeStrategyOverride(
    data.strategy_ab_test_segment_name_override,
    data.strategy_ab_test_segment_id_override_flag
      ? data.strategy_ab_test_segment_name_override
      : data.strategy_ab_test_segment_name,
  ),
  strategy_ab_test_segment_is_ab_test_control_combined: mergeStrategyOverride(
    data.strategy_ab_test_segment_is_ab_test_control_override,
    data.strategy_ab_test_segment_is_ab_test_control,
  ),
  strategy_ab_test_segment_external_source_identifier_combined: mergeStrategyOverride(
    data.strategy_ab_test_segment_external_source_identifier_override,
    data.strategy_ab_test_segment_external_source_identifier,
  ),
  strategy_ab_test_segment_source_id_combined: mergeStrategyOverride(
    data.strategy_ab_test_segment_source_id_override,
    data.strategy_ab_test_segment_source_id,
  ),
  strategy_ab_test_segment_ttl_days_combined: mergeStrategyOverride(
    data.strategy_ab_test_segment_ttl_days_override,
    data.strategy_ab_test_segment_ttl_days,
  ),
  strategy_bid_algorithm_id_combined: mergeStrategyOverride(
    data.strategy_bid_algorithm_id_override,
    data.strategy_bid_algorithm_id,
  ),
  strategy_bid_algorithm_name_combined: mergeStrategyOverride(
    data.strategy_bid_algorithm_name_override,
    data.strategy_bid_algorithm_name,
  ),
  strategy_bid_algorithm_short_name_combined: mergeStrategyOverride(
    data.strategy_bid_algorithm_short_name_override,
    data.strategy_bid_algorithm_short_name,
  ),
  strategy_budget_type_id_combined: mergeStrategyOverride(
    data.strategy_budget_type_id_override,
    data.strategy_budget_type_id,
  ),
  strategy_budget_type_name_combined: mergeStrategyOverride(
    data.strategy_budget_type_name_override,
    data.strategy_budget_type_name,
  ),
  strategy_budget_weight_combined: mergeStrategyOverride(
    data.strategy_budget_weight_override,
    data.strategy_budget_weight,
  ),
  strategy_budget_percent_combined: mergeStrategyOverride(null, data.strategy_budget_percent),
  strategy_spend_budget_micro_usd_combined: mergeStrategyOverride(null, data.strategy_spend_budget_micro_usd),
  strategy_impression_budget_combined: mergeStrategyOverride(null, data.strategy_impression_budget),
  strategy_creative_selection_id_combined: mergeStrategyOverride(
    data.strategy_creative_selection_id_override,
    data.strategy_creative_selection_id,
  ),
  strategy_creative_selection_name_combined: mergeStrategyOverride(
    data.strategy_creative_selection_name_override,
    data.strategy_creative_selection_name,
  ),
  strategy_daily_overspend_factor_combined: mergeStrategyOverride(
    data.strategy_daily_overspend_factor_override,
    data.strategy_daily_overspend_factor,
  ),
  strategy_name_combined: mergeStrategyOverride(data.strategy_name_override, data.strategy_name),
  strategy_intervals_per_day_combined: mergeStrategyOverride(
    data.strategy_intervals_per_day_override,
    data.strategy_intervals_per_day,
  ),
  strategy_id_combined: mergeStrategyOverride(data.strategy_id_override, data.strategy_id),
  strategy_priority_combined: mergeStrategyOverride(data.strategy_priority_override, data.strategy_priority),
  strategy_reporting_group_id_combined: mergeStrategyOverride(
    data.strategy_reporting_group_id_override,
    data.strategy_reporting_group_id,
  ),
  strategy_reporting_group_name_combined: mergeStrategyOverride(
    data.strategy_reporting_group_name_override,
    data.strategy_reporting_group_name,
  ),
  strategy_state_id_combined: mergeStrategyOverride(data.strategy_state_id_override, data.strategy_state_id),
  strategy_state_name_combined: mergeStrategyOverride(data.strategy_state_name_override, data.strategy_state_name),
  strategy_filter_json_combined: strategyObjectMerge(data.strategy_filter_json_override, data.strategy_filter_json),
  strategy_control_systems_json_combined: {
    ...data.strategy_control_systems_json,
    ...data.strategy_control_systems_json_override,
  },
  strategy_model_json_combined: {
    ...data.strategy_model_json,
    ...data.strategy_model_json_override,
  },
  strategy_pricing_json_combined: {
    ...data.strategy_pricing_json,
    ...data.strategy_pricing_json_override,
  },
  strategy_scoring_json_combined: {
    ...data.strategy_scoring_json,
    ...data.strategy_scoring_json_override,
  },
  strategy_creative_selection_json_combined: strategyObjectMerge(
    data.strategy_creative_selection_json_override,
    data.strategy_creative_selection_json,
  ),
  strategy_pacing_weight_json_combined: strategyArrayMerge(
    data.strategy_pacing_weight_json_override,
    data.strategy_pacing_weight_json,
  ),
});

const countStrategyUpdates = (data) => {
  const strategy_budget_changes = {
    strategy_budget_type_id_updated: strategyUpdateCount(
      data.strategy_budget_type_id_override,
      data.strategy_budget_type_id,
    ),
    strategy_budget_weight_updated: strategyUpdateCount(
      data.strategy_budget_weight_override,
      data.strategy_budget_weight,
    ),
  };

  const strategy_general_changes = {
    strategy_daily_overspend_factor_updated: strategyUpdateCount(
      data.strategy_daily_overspend_factor_override,
      data.strategy_daily_overspend_factor,
    ),
    strategy_ab_test_id_updated: data.strategy_ab_test_segment_id_override_flag
      ? strategyUpdateCount(data.strategy_ab_test_id, data.strategy_ab_test_id_override)
      : strategyUpdateCount(data.strategy_ab_test_id_override, data.strategy_ab_test_id),
    strategy_ab_test_segment_id_updated: data.strategy_ab_test_segment_id_override_flag
      ? strategyUpdateCount(data.strategy_ab_test_segment_id, data.strategy_ab_test_segment_id_override)
      : strategyUpdateCount(data.strategy_ab_test_segment_id_override, data.strategy_ab_test_segment_id),
    strategy_creative_selection_id_updated: strategyUpdateCount(
      data.strategy_creative_selection_id_override,
      data.strategy_creative_selection_id,
    ),
    strategy_name_updated: strategyUpdateCount(data.strategy_name_override, data.strategy_name),
    strategy_intervals_per_day_updated: strategyUpdateCount(
      data.strategy_intervals_per_day_override,
      data.strategy_intervals_per_day,
    ),
    strategy_reference_strategy_id_updated: strategyUpdateCount(
      data.strategy_reference_change_order_strategy_id_combined,
      data.strategy_reference_change_order_strategy_id,
    ),
    strategy_state_id_updated: strategyUpdateCount(data.strategy_state_id_override, data.strategy_state_id),
  };

  const strategy_json_changes = {
    strategy_filter_json_updated: strategyObjectCount(data.strategy_filter_json_override, data.strategy_filter_json),
    strategy_creative_selection_json_updated: strategyObjectCount(
      data.strategy_creative_selection_json_override,
      data.strategy_creative_selection_json,
    ),
    strategy_pacing_weight_json_updated: strategyArrayCount(
      data.strategy_pacing_weight_json_override,
      data.strategy_pacing_weight_json,
    ),
    strategy_control_systems_json_updated: strategyObjectCount(
      data.strategy_control_systems_json_override,
      data.strategy_control_systems_json,
    ),
    strategy_model_json_updated: strategyObjectCount(data.strategy_model_json_override, data.strategy_model_json),
    strategy_pricing_json_updated: strategyObjectCount(data.strategy_pricing_json_override, data.strategy_pricing_json),
    strategy_scoring_json_updated: strategyObjectCount(data.strategy_scoring_json_override, data.strategy_scoring_json),
  };

  // handle the warning counts
  const strategy_json_count = Object.keys(strategy_json_changes).reduce((a, b) => a + strategy_json_changes[b], 0);
  const strategy_budget_count = Object.keys(strategy_budget_changes).reduce(
    (a, b) => a + strategy_budget_changes[b],
    0,
  );
  const strategy_general_count = Object.keys(strategy_general_changes).reduce(
    (a, b) => a + strategy_general_changes[b],
    0,
  );

  const strategy_cumulative_count = strategy_json_count + strategy_budget_count + strategy_general_count;
  return {
    strategy_updated: strategy_cumulative_count > 0 ? 1 : 0,
    strategy_json_count,
    strategy_budget_count,
    strategy_general_count,
    strategy_cumulative_count,
    ...strategy_json_changes,
    ...strategy_budget_changes,
    ...strategy_general_changes,
  };
};
