import React, { Suspense, useEffect } from 'react';
import { ThemeProvider } from '@cognitiv/cyprus-ui';
import * as Sentry from '@sentry/react';
import { updateSettings } from 'ducks/actions/settings';
import { getLookups } from 'ducks/operators/lookups';
import { createBrowserHistory } from 'history';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRouter } from 'routes/PrivateRouter';
import { PublicRouter } from 'routes/PublicRouter';
import { routes } from 'routes/routes';
import { handleError } from 'utils/errors';
import { getSettings } from 'utils/storage';

import { Loading } from './components/loading/Loading';
import * as pages from './pages/pages';

const history = createBrowserHistory();

const { REACT_APP_ENVIRONMENT, REACT_APP_SENTRY_DNS } = process.env;

if (REACT_APP_ENVIRONMENT !== 'development') {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DNS,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}

const theme = {
  colors: {
    primary: '#e855b6',
    secondary: '#343a46',
  },
  globals: {
    primary_font: "'Roboto', sans-serif",
    secondary_font: "'Roboto', sans-serif",
    monospace_font: '',
    font_size: '13px',
    font_primary_color: '#404244',
    switch_on_color: '#e855b6',
    placeholder_color: '#83878a',
    border_color: '#b6bac0',
  },
  labels: {
    label_font_weight: 400,
    label_font_color: '#404244',
  },
  modal: {
    modal_border: '1px solid #b6bac0',
    modal_close_color: '#e855b6',
    modal_background: 'white',
    modal_border_radius: '12px',
  },
  editor: {
    action_padding: '3px 10px',
  },
  actions: {
    action_padding: '3px 10px',
  },
  inputs: {
    input_font_color: '#404244',
    input_border: '1px solid #b6bac0',
    input_background: '#fff',
    input_border_focus: '1px solid #e855b6',
    input_border_disabled: '1px solid #b6bac0',
  },
  wizard: {
    progress_background: '#f8f9fe',
    progress_bar_background: '#55a3ef',
    wizard_border_radius: '12px',
  },
  dropdowns: {
    dropdown_border: '1px solid #e855b6',
    dropdown_options_font_color_active: '#e855b6',
    dropdown_options_font_color_hover: '#e855b6',
    dropdown_options_background_hover: '#fff',
    dropdown_options_background_selected: '#fff',
    dropdown_options_background_active: '#fff',
  },
  switches: {
    label_font_weight: 400,
  },
  tooltips: {
    tooltip_font_color: '#fff',
    tooltip_background: '#343a46',
    tooltip_border: '1px solid #343a46',
  },
  tables: {
    table_header_background: '#f8f9fe',
    table_header_font_color: '#404244',
    table_header_font_color_hover: '#e855b6',
  },
  calendar: {
    calendar_background: '#fff',
    calendar_primary: '#e855b6',
    calendar_secondary: '#fff',
    calendar_font_size: '12px',
    calendar_font_color: '#404244',
    calendar_range_font_color: '#404244',
    calendar_border: '1px solid #bfbfbf',
  },
};

export const App = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(
    (state) => ({
      user: state.user,
    }),
    shallowEqual,
  );
  useEffect(() => {
    const { private_key, public_key, display_name } = user;
    const setupGemini = async () => {
      try {
        if (private_key && public_key) {
          await dispatch(getLookups());
          // dev note: getSettings pulls some settings from local storage
          const settings = getSettings();
          dispatch(updateSettings({ configuration: true, ...settings }));
        }
      } catch (err) {
        handleError({ ...err, display_name });
      }
    };
    setupGemini();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <Router basename="/" history={history}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loading variant="light" />}>
          <Switch>
            <Redirect exact from={routes.ROOT} to={routes.ADVERTISERS} />
            <Route exact path={routes.HEALTH} component={() => <p>health check</p>} />
            <PublicRouter exact path={routes.LOGIN} component={pages.LoginPage} />
            <PublicRouter exact path={routes.PASSWORD_REQUEST} component={pages.RequestPasswordPage} />
            <PublicRouter exact path={routes.PASSWORD_RESET} component={pages.ResetPasswordPage} />
            <PrivateRouter exact path={routes.ADVERTISERS} component={pages.AdvertisersPage} />
            <PrivateRouter exact path={routes.ADVERTISER} component={pages.AdvertiserPage} />
            <PrivateRouter exact path={routes.ADVERTISER_REPORT} component={pages.AdvertiserReportPage} />
            <PrivateRouter exact path={routes.FILTER} component={pages.FilterPage} />
            <PrivateRouter exact path={routes.CAMPAIGN_COPY} component={pages.CampaignCopyPage} />
            <PrivateRouter exact path={routes.CAMPAIGN} component={pages.CampaignPage} />
            <PrivateRouter exact path={routes.FLIGHT_COPY} component={pages.FlightCopyPage} />
            <PrivateRouter exact path={routes.FLIGHT} component={pages.FlightPage} />
            <PrivateRouter exact path={routes.LINE_ITEM} component={pages.LineItemPage} />
            <PrivateRouter exact path={routes.TAG} component={pages.TagPage} />
            <PrivateRouter exact path={routes.CHANGE_ORDER} component={pages.ChangeOrderPage} />
            <PrivateRouter exact path={routes.CREATIVE} component={pages.CreativePage} />
            <PrivateRouter exact path={routes.STRATEGY_TEMPLATES} component={pages.StrategyTemplatesPage} />
            <PrivateRouter exact path={routes.DEALS} component={pages.DealsPage} />
            <PrivateRouter exact path={routes.VENDOR_FEES} component={pages.VendorFeesPage} />
            <PrivateRouter exact path={routes.MODELS} component={pages.ModelsPage} />
            <PrivateRouter component={pages.NotFoundPage} />
          </Switch>
        </Suspense>
      </ThemeProvider>
    </Router>
  );
};
