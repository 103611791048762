import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dropdown, DropdownCheckbox, Input, InputNumber, Modal } from '@cognitiv/cyprus-ui';
import { updateModal } from 'ducks/actions/modals';
import { clearSegment } from 'ducks/actions/segments';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { createSegment, updateSegment } from 'ducks/operators/segments';
import { removeTagsByState } from 'ducks/selectors/tags';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import cn from './Modal.module.scss';

const default_state = {
  segment_name: '',
  ab_test_id: null,
  ab_test_name: '',
  tag_ids: [],
  segment_tags: [],
  ttl_days: null,
  sync_with_dsp: false,
  allocation_weight: null,
  change_order_name: '',
  change_order_id: null,
  identity_type_id: null,
  identity_type_name: '',
};

export const ManageSegment = ({ history, match, location }) => {
  const dispatch = useDispatch();

  const { segment, tags, modals, identity_types_list } = useSelector(
    (state) => ({
      segment: state.segment,
      tags: removeTagsByState(state.tags, 4),
      modals: state.modals,
      identity_types_list: state.identity_types_list,
    }),
    shallowEqual,
  );

  const [form, setForm] = useState({ ...default_state });

  useEffect(() => {
    if (segment.segment_id) {
      const { tag_ids } = segment;
      const segment_tags = tags.map((t) => ({ ...t, checked: tag_ids.includes(t.tag_id) }));
      setForm({ ...segment, segment_tags });
    }
    if (!segment.segment_id) {
      setForm({ ...default_state, segment_tags: tags });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment]);

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const closeModal = () => {
    // checking params to validate on segment page
    if (!match.params.segment_id) {
      dispatch(clearSegment());
      setForm({ ...default_state });
    }

    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_segment: false }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { search, pathname } = location;
    const { segment_id } = segment;
    const { advertiser_id } = match.params;
    const queries = mergeQuery(search, { update: uuidv4() });
    try {
      const data = {
        advertiser_id,
        segment_name: form.segment_name,
        ab_test_id: null,
        tag_ids: segment_tags.filter((s) => s.checked).map((s) => s.tag_id),
        ttl_days: form.ttl_days,
        sync_with_dsp: form.sync_with_dsp,
        allocation_weight: null,
        change_order_id: null,
        identity_type_id: form.identity_type_id,
      };

      if (segment_id) {
        await dispatch(updateSegment(segment_id, data));
        dispatch(updateSuccess('segment has been successfully updated'));
      }
      if (!segment_id) {
        await dispatch(createSegment(advertiser_id, data));
        dispatch(updateSuccess('segment has been successfully created'));
      }

      history.push(`${pathname}${queries}`);
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const { segment_id } = segment;
  const { segment_name, segment_tags, sync_with_dsp, ttl_days_formatted, identity_type_name } = form;

  return (
    <Modal
      padding={16}
      close_box={46}
      name="manage_segment"
      show={modals.manage_segment}
      width={450}
      onClose={closeModal}
      standard={false}
    >
      <h3>{segment_id ? 'Update Segment' : 'Create Segment'}</h3>
      <form onSubmit={onSubmit}>
        <Input
          auto_focus
          label="Segment Name"
          value={segment_name}
          onChange={(value) =>
            onChange({
              segment_name: value,
            })
          }
        />
        <DropdownCheckbox
          label="Tags"
          options={segment_tags}
          option_key="tag_name"
          onSelect={(items) => onChange({ segment_tags: items })}
          margin="15px 0px 0px 0px"
        />
        <div style={{ display: 'flex' }}>
          <InputNumber
            label="Time To Live (Days)"
            value={ttl_days_formatted}
            margin="15px 7px 0px 0px"
            onChange={(input) => onChange({ ttl_days: input.float_value, ttl_days_formatted: input.formatted_value })}
          />
          <Dropdown
            disabled={segment_id}
            options={identity_types_list}
            option_key="name"
            value={identity_type_name}
            label="Identity Type"
            margin="15px 0px 0px 7px"
            onSelect={(item) => onChange({ identity_type_id: item.id, identity_type_name: item.name })}
          />
        </div>
        {!segment_id && (
          <Checkbox
            margin="10px 0px 0px 0px"
            width="max-content"
            checked={sync_with_dsp}
            onClick={(checked) => onChange({ sync_with_dsp: checked })}
          >
            Sync DSP
          </Checkbox>
        )}
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button button_size="small" width="100px" margin="10px 0px 0px 0px" type="submit" disabled={!segment_name}>
            {segment_id ? 'Save' : 'Create'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

ManageSegment.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};
