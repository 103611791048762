import React from 'react';
import { Tooltip } from '@cognitiv/cyprus-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Advertisers } from 'components/navigation/Advertisers';
import { Deals } from 'components/navigation/Deals';
import { logoutUser } from 'ducks/operators/users';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes/routes';

import { Admin } from './Admin';
import { Advertiser } from './Advertiser';
import { Campaign } from './Campaign';
import { CampaignCopy } from './CampaignCopy';
import { ChangeOrder } from './ChangeOrder';
import { Creative } from './Creative';
import { Filter } from './Filter';
import { Flight } from './Flight';
import { FlightCopy } from './FlightCopy';
import { LineItem } from './LineItem';
import { Models } from './Models';
import { Tag } from './Tag';
import { VendorFees } from './VendorFees';
import { StrategyTemplates } from './StrategyTemplates';

import cn from './Navigation.module.scss';

export const Navigation = () => {
  const dispatch = useDispatch();
  return (
    <div className={cn.navigation}>
      <div className={cn.nav}>
        <Switch>
          <Route exact path={routes.ADVERTISERS} component={Advertisers} />
          <Route exact path={routes.ADVERTISER} component={Advertiser} />
          <Route exact path={routes.FILTER} component={Filter} />
          <Route exact path={routes.CAMPAIGN} component={Campaign} />
          <Route exact path={routes.CAMPAIGN_COPY} component={CampaignCopy} />
          <Route exact path={routes.FLIGHT} component={Flight} />
          <Route exact path={routes.FLIGHT_COPY} component={FlightCopy} />
          <Route exact path={routes.LINE_ITEM} component={LineItem} />
          <Route exact path={routes.CREATIVE} component={Creative} />
          <Route exact path={routes.CHANGE_ORDER} component={ChangeOrder} />
          <Route exact path={routes.DEALS} component={Deals} />
          <Route exact path={routes.VENDOR_FEES} component={VendorFees} />
          <Route exact path={routes.STRATEGY_TEMPLATES} component={StrategyTemplates} />
          <Route exact path={routes.TAG} component={Tag} />
          <Route exact path={routes.MODELS} component={Models} />
          <Route exact path={routes.ADMIN} component={Admin} />
        </Switch>
      </div>
      <div className={cn.action} onClick={() => dispatch(logoutUser())}>
        <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
        <Tooltip tooltip="Logout" position={{ right: `calc(100% + 10px)` }} />
      </div>
    </div>
  );
};
